import React from "react";

function Icon({ width = 225, height = 36}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 225 36"
    >
      <g fillRule="evenodd" clipRule="evenodd">
        <path
          fill="#fff"
          d="M54.573 17.31h-3.605v6.59c0 .55.138.95.415 1.204.276.252.68.396 1.212.427.532.032 1.19.026 1.978-.016v3.706c-2.829.317-4.823.053-5.982-.792-1.16-.844-1.74-2.354-1.74-4.53v-6.588h-2.775v-3.928h2.776V8.948h4.117v4.435h3.604v3.928z"
        ></path>
        <path
          fill="#fff"
          d="M65.07 12.94c1.744 0 3.185.581 4.323 1.742 1.137 1.16 1.706 2.766 1.706 4.815v9.724h-4.114v-9.217c0-1.056-.287-1.864-.862-2.424-.575-.56-1.34-.84-2.297-.84-1.064 0-1.915.328-2.553.983-.637.654-.957 1.637-.957 2.945v8.553H56.2V7.048h4.116v8.109c1-1.478 2.585-2.218 4.754-2.218z"
        ></path>
        <path
          fill="#fff"
          d="M76.747 19.813h8.135c-.234-1.077-.717-1.879-1.45-2.408a4.188 4.188 0 00-2.505-.792c-1.107 0-2.021.282-2.744.84-.724.56-1.202 1.346-1.436 2.36zm.064 3.168c.553 1.986 2.052 2.977 4.498 2.977 1.574 0 2.765-.527 3.573-1.583l3.318 1.9c-1.574 2.26-3.892 3.39-6.955 3.39-2.637 0-4.753-.792-6.349-2.376-1.595-1.584-2.392-3.58-2.392-5.987 0-2.386.786-4.377 2.36-5.971 1.575-1.595 3.595-2.392 6.063-2.392 2.338 0 4.269.803 5.79 2.408 1.52 1.604 2.281 3.59 2.281 5.955 0 .528-.053 1.088-.16 1.679H76.811z"
        ></path>
        <path
          fill="#fff"
          d="M103.476 25.04c1.936 0 3.51-.64 4.722-1.916 1.213-1.278 1.819-2.941 1.819-4.99 0-2.048-.606-3.71-1.819-4.988-1.212-1.279-2.786-1.917-4.722-1.917H99.01V25.04h4.466zm0-17.992c3.063 0 5.621 1.066 7.673 3.199 2.053 2.133 3.079 4.762 3.079 7.887 0 3.126-1.026 5.755-3.079 7.887-2.052 2.134-4.61 3.2-7.673 3.2h-8.87V7.048h8.87z"
        ></path>
        <path
          fill="#fff"
          d="M115.887 29.22h4.115V13.384h-4.115V29.22zm2.042-17.738c-.681 0-1.272-.248-1.77-.743-.501-.498-.751-1.083-.751-1.76 0-.674.25-1.266.751-1.773.498-.507 1.089-.76 1.77-.76.702 0 1.302.253 1.802.76s.75 1.099.75 1.774c0 .676-.25 1.261-.75 1.759-.5.495-1.1.743-1.802.743z"
        ></path>
        <path
          fill="#fff"
          d="M127.038 24.169c.84.813 1.909 1.22 3.207 1.22 1.296 0 2.365-.407 3.206-1.22.84-.813 1.26-1.853 1.26-3.12 0-1.246-.42-2.275-1.26-3.089-.841-.813-1.91-1.22-3.206-1.22-1.298 0-2.367.407-3.207 1.22-.841.814-1.26 1.843-1.26 3.088 0 1.268.419 2.308 1.26 3.12zm7.673-10.786h4.019v15.078c0 2.45-.813 4.319-2.439 5.607-1.628 1.287-3.59 1.932-5.888 1.932-1.722 0-3.237-.328-4.545-.983a6.89 6.89 0 01-3.016-2.881l3.51-1.997c.809 1.479 2.202 2.218 4.18 2.218 1.296 0 2.318-.338 3.062-1.013.745-.676 1.117-1.637 1.117-2.884v-1.71c-1.212 1.605-2.914 2.408-5.105 2.408-2.255 0-4.142-.787-5.663-2.36s-2.281-3.49-2.281-5.75c0-2.238.766-4.149 2.298-5.733 1.531-1.584 3.413-2.376 5.646-2.376 2.191 0 3.893.803 5.105 2.409v-1.965z"
        ></path>
        <path
          fill="#fff"
          d="M141.187 29.22h4.115V13.384h-4.115V29.22zm2.042-17.738c-.681 0-1.272-.248-1.771-.743-.5-.498-.749-1.083-.749-1.76 0-.674.249-1.266.749-1.773.499-.507 1.09-.76 1.771-.76.701 0 1.302.253 1.803.76.499.507.749 1.099.749 1.774 0 .676-.25 1.261-.749 1.759a2.474 2.474 0 01-1.803.743z"
        ></path>
        <path
          fill="#fff"
          d="M156.916 17.31h-3.605v6.59c0 .55.137.95.414 1.204.277.252.681.396 1.213.427.531.032 1.191.026 1.978-.016v3.706c-2.829.317-4.823.053-5.983-.792-1.159-.844-1.738-2.354-1.738-4.53v-6.588h-2.776v-3.928h2.776V8.964l4.116-.016v4.435h3.605v3.928z"
        ></path>
        <path
          fill="#fff"
          d="M162.596 24.517c.829.835 1.882 1.252 3.158 1.252 1.275 0 2.334-.417 3.175-1.252.84-.834 1.259-1.905 1.259-3.215 0-1.309-.419-2.38-1.259-3.215-.841-.835-1.9-1.252-3.175-1.252-1.276 0-2.329.417-3.158 1.252-.83.834-1.245 1.906-1.245 3.215 0 1.31.415 2.381 1.245 3.215zm7.592-11.134h4.116V29.22h-4.116v-1.868c-1.233 1.54-2.967 2.312-5.2 2.312-2.127 0-3.951-.808-5.471-2.423-1.521-1.617-2.282-3.596-2.282-5.94 0-2.344.761-4.325 2.282-5.94 1.52-1.614 3.344-2.423 5.471-2.423 2.233 0 3.967.77 5.2 2.313v-1.87z"
        ></path>
        <path fill="#fff" d="M176.761 29.221h4.114V7.048h-4.114V29.22z"></path>
        <path
          fill="#EB175C"
          d="M196.894 25.135c.744 0 1.361-.248 1.851-.745.489-.495.734-1.124.734-1.884 0-.739-.245-1.357-.734-1.853-.49-.496-1.107-.745-1.851-.745h-5.073v5.227h5.073zm-.67-14.002h-4.403v4.879h4.403c.681 0 1.25-.233 1.707-.697.457-.465.685-1.045.685-1.742 0-.696-.222-1.278-.669-1.743-.447-.463-1.021-.697-1.723-.697zm4.691 6.59c1.956 1.119 2.935 2.809 2.935 5.069 0 1.88-.671 3.42-2.011 4.624-1.34 1.203-2.989 1.805-4.945 1.805h-9.475V7.047h8.805c1.914 0 3.526.587 4.834 1.76 1.309 1.17 1.961 2.665 1.961 4.48 0 1.839-.702 3.317-2.104 4.436z"
        ></path>
        <path
          fill="#EB175C"
          d="M211.378 21.143h6.285l-3.222-9.059-3.063 9.06zm9.159 8.078l-1.419-3.991h-9.174l-1.341 3.991h-4.752l7.815-22.173h5.456L225 29.22h-4.463z"
        ></path>
        <path
          fill="#004CFF"
          d="M15.53 2.716H0v30.836h15.53c8.575 0 15.528-6.902 15.528-15.418H15.53V2.716z"
        ></path>
        <path
          fill="#1B00BD"
          d="M0 18.134c0 8.515 6.953 15.418 15.53 15.418V18.134H0z"
        ></path>
        <path
          fill="#004CFF"
          d="M31.058 18.134c0 8.515-6.952 15.418-15.529 15.418V18.134h15.53z"
        ></path>
        <path
          fill="#004CFF"
          d="M0 18.134C0 9.62 6.953 2.716 15.53 2.716v15.418H0z"
        ></path>
        <path
          fill="#EB175C"
          d="M18.265 0v15.418h15.529C33.794 6.903 26.84 0 18.264 0z"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
